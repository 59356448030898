<template>
  <div class="default-modal search-modal">
    <div class="default-modal__header search-modal__header">
      <h3 class="default-modal__title search-modal__title">Результаты</h3>
      <button type="button" class="default-modal__close search-modal__close" @click="$emit('close')">
        <IconComponent name="close-fill" />
      </button>
    </div>
    <div v-if="!loading" class="default-modal__body search-modal__body">
      <div v-if="heroes && heroes.length" class="search-modal__heroes">
        <span class="search-modal__subtitle">Герои:</span>
        <div class="search-modal__heroes-content">
          <HeroCardComponent v-for="(hero, i) in heroes" :key="i" :hero="hero" />
        </div>
      </div>
      <div v-if="news && news.length" class="search-modal__news">
        <span class="search-modal__subtitle">Новости:</span>
        <div class="search-modal__news-content">
          <NewsCardComponent v-for="(news_item, i) in news" :key="i" :news="news_item" />
        </div>
      </div>
      <div v-if="!news.length && !heroes.length" class="search-modal__empty">
        <h3>Ничего не найдено</h3>
      </div>
    </div>
    <LoadingIndicator v-if="loading" title="Загрузка..." />
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import HeroCardComponent from "components/HeroCardComponent.vue";
import NewsCardComponent from "components/NewsCardComponent.vue";
import SEARCH from "@/graphql/queries/search.graphql";
import LoadingIndicator from "components/Loadingindicator.vue";

export default {
  name: "SearchModal",
  props: {
    title: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    heroes() {
      return this.$store.state.search_modal.heroes;
    },
    news() {
      return this.$store.state.search_modal.news;
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      if (!this.loading) {
        this.loading = true;
        this.$apolloProvider.defaultClient
          .query({
            query: SEARCH,
            context: {
              fetchPolicy: "no-cache",
            },
            variables: {
              title: this.title,
            },
          })
          .then(({ data }) => {
            this.$store.state.search_modal.heroes = data.heroes_paginate.data;
            this.$store.state.search_modal.news = data.news_paginate.data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  components: { LoadingIndicator, NewsCardComponent, HeroCardComponent, IconComponent },
};
</script>

<style lang="stylus">
.search-modal {
  width 100%
  max-width 1252px
  padding 15px 0 15px 20px

  //&--center &__header {
  //  justify-content center
  //}

  &__title {
    font-size 1.5rem
    font-weight 500
    line-height 28px
  }

  &__close {
    margin-right 15px
  }

  &__body {
    gap 30px
  }

  &__subtitle {
    font-size 1.125rem
    line-height 24px
  }

  &__heroes {
    display flex
    flex-direction column
    gap 15px
    width 100%
  }

  &__heroes-content {
    display flex
    gap 30px
    overflow-x auto
    width 100%
    padding-right 15px
    scrollbar-width: none

    .hero-card {
      //min-width 320px
      //max-width 320px
      min-width 276px
      max-width 276px
      gap 10px

      &__image {
        //height 320px
        //max-height 320px
        height 250px
        max-height 250px
      }

      &__text {
        display flex
        text-align center
        position static
        gap 5px
      }

      &__title {
        font-size 1.25rem
        line-height 28px
      }

      &__rank {
        font-size .875rem
        line-height 22px
      }
    }
  }

  &__news {
    display flex
    flex-direction column
    gap 15px
    overflow-x auto
    width 100%
  }

  &__news-content {
    display flex
    gap 20px
    width 100%
    overflow-x auto
    padding-right 15px
    scrollbar-width none

    .news-card {
      min-width 384px
      gap 10px

      &__image {
        //height 320px
        //max-height 320px
        height 250px
        max-height 250px
      }
    }
  }

  //&__message {
  //  width 100%
  //  text-align center
  //  white-space pre-line
  //}

  //& &__footer {
  //  flex-direction row
  //
  //  .btn {
  //    width 100%
  //    justify-content center
  //  }
  //}

  &__empty {
    display flex
    justify-content center
    align-items center
    width 100%
    padding 250px 0
  }
}
</style>
