<template>
  <router-link :to="{ name: 'news-item', params: { id: news.id } }" v-if="news" class="news-card">
    <ImgComponent :img="news.img" class="news-card__image" />
    <span class="news-card__date">{{ news.published_at | formatDate }}</span>
    <span class="news-card__title">{{ news.title }}</span>
  </router-link>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "NewsCardComponent",
  props: {
    news: {
      type: Object,
      default: () => {},
    },
  },
  components: { ImgComponent },
};
</script>

<style lang="stylus">
.news-card {
  width 100%
  max-width 384px
  display flex
  flex-direction column
  gap 15px
  +below(650px) {
    max-width 100%
  }

  &__image {
    height 257px
    max-height 257px
    border-radius var(--main-radius)
    //+below(650px) {
    //  height 300px
    //  max-height 300px
    //}
  }

  &__date {
    font-size .875em
    line-height 22px
    color var(--gray)
  }

  &__title {
    font-size 1.125em
    font-weight 700
    line-height 28px
  }
}
</style>
