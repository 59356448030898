<template>
  <footer class="footer">
    <div class="container-padding">
      <div class="footer__content">
        <div class="footer__links">
          <router-link :to="link.route" v-for="(link, i) in links" :key="i" class="footer__link">
            <span class="footer__link-title">{{ link.title }}</span>
          </router-link>
        </div>
        <div class="footer__copyright">
          <div class="footer__socials">
            <a v-for="(social, i) in socials" :key="i" :href="social.link" target="_blank">
              <IconComponent :name="social.name" />
            </a>
          </div>
          <a :href="`mailto:${email}`">
            <span class="footer__copyright-title">{{ email }}</span>
          </a>
          <span class="footer__copyright-title">© 2024. Герои Спецоперации</span>
          <span class="footer__copyright-title">
            Разработка проекта ведется Министерством цифрового развития Республики Дагестан совместно
            с сообществом «Сила V единстве»
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "FooterComponent",
  components: { IconComponent },
  data() {
    return {
      email: "hunzahp@mail.ru",
      links: [
        {
          title: "Главная",
          route: { name: "home" },
        },
        {
          title: "Новости",
          route: { name: "news" },
        },
        {
          title: "Сила V единстве",
          route: { name: "power" },
        },
      ],
      socials: [
        { name: "vk-white", link: "https://vk.com/id841553396" },
        { name: "tg-white", link: "https://t.me/+omft_2SyI2EyMjcy" },
      ],
    };
  },
};
</script>

<style lang="stylus">
.footer {
  padding 130px 0
  background var(--gray)
  +below(650px) {
    padding 30px 0
  }

  &__content {
    display grid
    grid-template-columns repeat(2, 1fr)
    +below(650px) {
      display flex
      flex-direction column
      gap 30px
    }
  }

  &__links {
    display flex
    flex-direction column
    align-items flex-start
    gap 15px
    +below(650px) {
      gap 10px
    }
  }

  &__link-title {
    font-size 1.125em
    line-height 26px
    font-family var(--font-regular-2)
    color var(--white)
    +below(650px) {
      font-size .875em
      line-height 22px
    }
  }

  &__copyright {
    display flex
    flex-direction column
    justify-content flex-end
    gap 15px
  }

  &__copyright-title {
    font-size .875em
    line-height 17px
    font-family var(--font-regular-2)
    color var(--white_o5)
  }

  &__socials {
    display flex
    align-items center
    gap 15px

    .icon svg {
      width 30px
      height 30px
    }
  }
}
</style>
