<template>
  <router-link :to="{ name: 'hero', params: { id: hero.id } }" class="hero-card">
    <ImgComponent :img="hero.img" class="hero-card__image" />
    <div class="hero-card__text">
      <span class="hero-card__title">{{ hero.title }}</span>
      <span class="hero-card__rank">{{ hero.rank }}</span>
    </div>
  </router-link>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
export default {
  name: "HeroCardComponent",
  props: {
    hero: {
      type: Object,
      default: () => {},
    },
  },
  components: { ImgComponent },
};
</script>

<style lang="stylus">
.hero-card {
  max-width 276px
  width 100%
  display flex
  flex-direction column
  gap 20px
  color var(--dark)
  +below(500px) {
    max-width 212px
  }
  +below(428px) {
    max-width 175px
  }
  +below(428px) {
    max-width 100%
  }

  &__image {
    height 276px
    max-height 276px
    border-radius var(--big-radius)
    +below(500px) {
      height 212px
      max-height 212px
    }
    +below(428px) {
      height 175px
      max-height 175px
    }
    +below(428px) {
      height 300px
      max-height 300px
    }
  }

  &__text {
    //display none
    display flex
    flex-direction column
    gap 15px
    width 100%
    //position absolute
    //top 330px
    +below(428px) {
      top 200px
      gap 5px
    }
  }

  &__title {
    font-size 1.375rem
    font-weight 700
    line-height 32px
    +below(418px) {
      font-size .875rem
    }
  }

  &__rank {
    font-size 1rem
    line-height 26px
    display -webkit-box
    overflow hidden
    -webkit-box-orient vertical
    -webkit-line-clamp 3
    line-clamp 3
    +below(418px) {
      font-size .875rem
    }
  }
}
</style>
