<template>
  <header :class="{ 'header--black': $route.name !== 'home' }" class="header">
    <div class="container-padding">
      <div class="header__content">
        <div class="header__links">
          <div v-for="(link, i) in links" :key="i" class="header__link">
            <router-link :to="link.route" class="header__link-item">
              <IconComponent v-if="link.icon" :name="link.icon" class="header__link-icon" />
              <span class="header__link-title">{{ link.title }}</span>
            </router-link>
            <div v-if="link.subLinks && link.subLinks.length" class="header__sublinks">
              <ul>
                <li v-for="(subLink, i) in link.subLinks" :key="i">
                  <router-link :to="subLink.route">
                    <span class="header__sublink-title">{{ subLink.title }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <InputComponent placeholder="Герои, новости" v-model="search" @enter="openSearchModal">
          <template #action>
            <IconComponent name="search" />
          </template>
        </InputComponent>
      </div>
    </div>
  </header>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import SearchModal from "components/modals/components/SearchModal.vue";

export default {
  name: "HeaderComponent",
  data() {
    return {
      search: "",
      links: [
        {
          title: "Главная",
          icon: "star",
          route: { name: "home" },
        },
        {
          title: "Новости",
          icon: "",
          route: { name: "news" },
        },
        {
          title: "Сила V единстве",
          icon: "",
          route: { name: "power" },
          // subLinks: [
          //   {
          //     title: "Новости",
          //     route: { name: "news" },
          //   },
          // ],
        },
      ],
    };
  },
  methods: {
    openSearchModal() {
      console.log("1");
      this.$store.state._modals.push({
        component: SearchModal,
        options: {
          title: this.search,
        },
      });
    },
    // test() {
    //   console.log("hello");
    // },
    // test2() {
    //   console.log("hello22");
    // },
    // test3() {
    //   console.log("test3");
    // },
    // test4() {
    //   console.log("test4");
    // },
  },
  components: {
    InputComponent,
    IconComponent,
  },
};
</script>

<style lang="stylus">
.header {
  width 100%
  position absolute
  top 50px
  z-index 1
  color var(--white)
  //+below(650px) {
  //  top 30px
  //}
  +below(450px) {
    top 30px
  }

  .input {
    width 100%
    max-width 488px
    +below(965px) {
      max-width 390px
    }
    +below(450px) {
      max-width 290px
    }
  }

  &--black {
    color var(--black)

    .icon svg path {
      fill var(--black)
      //stroke var(--black)
    }

    .input__container {
      border-color var(--black)
    }

    .input__action {
      .icon svg path {
        fill transparent
        stroke var(--black)
      }
    }

    .input__field {
      color var(--black)

      &::placeholder {
        color: var(--black);
      }

      &::-ms-input-placeholder {
        color: var(--black);
      }
    }
  }

  &__content {
    display flex
    align-items center
    justify-content space-between
    +below(945px) {
      flex-direction column
      align-items flex-start
      gap 20px
    }
  }

  &__links {
    display flex
    gap 50px
    //+below(650px) {
    //  gap 30px
    //}
    +below(480px) {
      gap 30px
    }
  }

  &__link {
    display flex
    flex-direction column
    align-items center
    gap 5px

    &:hover {
      .header__sublinks {
        opacity 1
        visibility visible
      }
    }
  }

  &__link-item {
    display flex
    align-items center
    gap 5px
  }

  &__link-title {
    font-size 1.125em
    line-height 26px
    //+below(650px) {
    //  font-size .875em
    //  line-height 22px
    //}
    +below(450px) {
      font-size .875em
      line-height 22px
    }
  }

  &__link-icon {
    width 16px
    max-width 16px
    height 16px
    max-height 16px
    //+below(650px) {
    //  width 14px
    //  max-width 14px
    //  height 14px
    //  max-height 14px
    //}
    +below(450px) {
      width 14px
      max-width 14px
      height 14px
      max-height 14px
    }
  }

  &__sublinks {
    opacity 0
    visibility hidden
    transition var(--transition)
    position absolute
    top calc(100% + 5px)
    padding 15px 10px 10px
    border-radius var(--main-radius)
    box-shadow: 0 4px 16px 1px rgba(0,26,52,.16);
    z-index 5
    background var(--white)
    width auto

    &::after {
      content ""
      display block
      background var(--white)
      width 10px
      height: 10px
      position absolute
      z-index 4
      top: 0
      left 50%
      transform translate(-50%, -50%) rotate(45deg)
    }
  }

  &__sublink-title {
    color var(--black)
    font-size .875em
    line-height 26px
    //+below(650px) {
    //  font-size .75em
    //  line-height 22px
    //}
    +below(450px) {
      font-size .75em
      line-height 22px
    }
  }
}
</style>
