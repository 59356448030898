import Vue from "vue";
import moment from "moment";
import { VueMaskFilter } from "v-mask";
import plural from "plural-ru";

Vue.filter("formatDate", (date) => {
  return moment(date).format("DD.MM.YYYY");
});

Vue.filter("humanDate", (date) => {
  moment.locale("ru");
  return moment(date).format("DD MMMM YYYY");
});

Vue.filter("robotDate", (date) => {
  return moment(date);
});

Vue.filter("plural", (count, ...words) => {
  return plural(count, ...words.map((w) => "%d " + w));
});

Vue.filter("plural_word", (count, ...words) => {
  return plural(count, ...words.map((w) => w));
});

Vue.filter("vMask", VueMaskFilter);

Vue.filter("image", (entity, api) => {
  if (entity && entity.img) {
    return api + entity.img.url;
  }
  return "/static/images/no-image.webp";
});

Vue.filter("image_alt", (entity) => {
  if (entity && entity.img) {
    return entity.img.alt;
  }
  return "";
});

/**
 * Проверка на наличие текста в EditorJs
 * если это текст от EditorJs и там пусто - false
 * undefined/null/'null' - false
 * любой другой текст - true
 */
Vue.filter("editor_has_text", (text) => {
  try {
    let parsedText = JSON.parse(text);
    if (typeof parsedText === "object") {
      return !!(parsedText && parsedText.blocks && parsedText.blocks.length);
    }
    return !!text;
  } catch (e) {
    if (typeof text === "string") {
      return !!text;
    }
    return false;
  }
});
